import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from '../auth.service';
import { MeInfoUser, UserData } from '../../models/logged-user.model';
import { UNHUserProfile } from '../../models/unh-user.model';
import { getProfilePicture } from '@frontend/unhideschool/app/helpers/relatedmedia-and-crop.helper';
import { StorageService } from '@frontend/unhideschool/app/api-gateway/services/storage.service';
import { environment } from '@frontend/unhideschool/env';

@Injectable({
  providedIn: 'root',
})
export class LoggedUserStateService {
  private readonly _user$ = new BehaviorSubject<MeInfoUser>(null);
  private readonly _userProfileInfo$ = new BehaviorSubject<UNHUserProfile>(
    null
  );
  private readonly _courseListIds$ = new BehaviorSubject<number[]>([]);
  private readonly _subscribed$ = new BehaviorSubject<boolean>(false);
  private readonly _isLogged$ = new BehaviorSubject<boolean>(false);
  private readonly _isAdmin$ = new BehaviorSubject<boolean>(false);
  private readonly _isGuardian$ = new BehaviorSubject<boolean>(false);
  private readonly _isPlanoTeam$ = new BehaviorSubject<boolean>(false);
  private readonly _isPlanoTeamOwner$ = new BehaviorSubject<boolean>(false);
  private readonly _workSpaceId$ = new BehaviorSubject<number>(0);
  private readonly SPACE_KEY = environment.storagekeys.unhspace;

  public user$ = this._user$.asObservable();
  public userProfileInfo$ = this._userProfileInfo$.asObservable();
  public courseListIds$ = this._courseListIds$.asObservable();
  public subscribed$ = this._subscribed$.asObservable();
  public isLogged$ = this._isLogged$.asObservable();
  public isAdmin$ = this._isAdmin$.asObservable();
  public isGuardian$ = this._isGuardian$.asObservable();
  public isPlanoTeam$ = this._isPlanoTeam$.asObservable();
  public isPlanoTeamOwner$ = this._isPlanoTeamOwner$.asObservable();
  public workSpaceId$ = this._workSpaceId$.asObservable();

  roles = {
    admin: 1,
    member: 2,
  };

  get uid() {
    return this.user && this.user.uid;
  }

  get user() {
    return this._user$.value;
  }

  get userProfileInfo() {
    return this._userProfileInfo$.value;
  }

  get subscribed() {
    return this._subscribed$.value;
  }

  get isLogged() {
    return this._isLogged$.value;
  }

  get isAdmin() {
    return this._isAdmin$.value;
  }

  get isGuardian() {
    return this._isGuardian$.value;
  }

  get courseListIds() {
    return this._courseListIds$.value;
  }

  get isPlanoTeam() {
    return this._isPlanoTeam$.value;
  }

  get isPlanoTeamOwner() {
    return this._isPlanoTeamOwner$.value;
  }

  get workSpaceId() {
    return this._workSpaceId$.value;
  }

  get space() {
    return this.storage.getItem(this.SPACE_KEY);
  }

  set space(value) {
    console.log(value, 'value');
    this.storage.setItem(this.SPACE_KEY, value);
  }

  constructor(private auths: AuthService, private storage: StorageService) {
    auths.authData$.subscribe((authData) => {
      try {
        const { me } = authData;
        this.updateLoggedUserStates(
          me?.user,
          me?.courseListIds,
          me?.subscribed
        );
      } catch (err) {
        this.updateLoggedUserStates(null, [], false);
      }
    });
  }

  getProfilePicture() {
    const url = this.user?.profilepicture || '';
    const relatedmedia = this.userProfileInfo?.relatedmedia;
    return getProfilePicture(url, relatedmedia);
  }

  updateLoggedUserProfileInfo(userProfile: Partial<UserData>) {
    this._userProfileInfo$.next(userProfile);
  }

  private updateLoggedUserStates(
    user: MeInfoUser,
    courseListIds: number[] = [],
    subscribed: boolean = false
  ) {
    const worksapce = user?.workspaces.find(
      (space) => space.workspace?.slug !== 'unhide_school'
    );

    this._user$.next(user);
    this._userProfileInfo$.next(user);
    this._courseListIds$.next(courseListIds);
    this._subscribed$.next(subscribed);
    this._isLogged$.next(user != null);
    this._isAdmin$.next(user?.rolename === 'adminuser');
    this._isGuardian$.next(user?.rolename === 'career_guardian');
    this._isPlanoTeam$.next(Boolean(worksapce));
    this._isPlanoTeamOwner$.next(worksapce?.roleId === this.roles.admin);
    this._workSpaceId$.next(worksapce?.workspaceId);
  }
}
