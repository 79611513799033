import { MeInfo } from '.';
import { MeAccessModel } from '../../modules/membership/membership-subscription.selectors';


export interface AuthData {
    token: string;
    me: MeInfo;
  }
  
export  enum AppLanguage {
    EN_US= 'en-US',
    PT_BR= 'pt-BR'
  }