import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from './../../services/project.service';
import { FetchLastWatchedLesson, FetchMentoringData, FetchOthersPosts, FetchViewallLessons, LoadmoreOthersPosts, UpdateOthersPostsPagination } from './ngxs/view-all.actions';
import { ViewAllSelectors } from './ngxs/view-all.selector';

import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Overlay } from '@angular/cdk/overlay';
import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Meta, Title } from '@angular/platform-browser';
import { groupCollectionByKey } from '@frontend/unhideschool/app/helpers/group-collection-by-key.func';
import { ViewAllDialogComponent } from '@frontend/unhideschool/modules/project/dialogs/view-all/view-all-dialog.component';
import { SpinnerOverlayComponent } from '@frontend/unhideschool/modules/project/overlays/spinner/spinner-overlay.component';
import { PostRelatedUrlFilesState, ProjectStore } from '@frontend/unhideschool/modules/project/services/project.store';
import { Store } from '@ngxs/store';
import { LessonItem } from '@frontend/unhideschool/shared/models/project/lesson-item.model';
import { ProjectPost } from '@frontend/unhideschool/shared/models/project/project-post.model';
import { UtilityService } from '@frontend/unhideschool/shared/services/utility.service';

import { HttpClient, HttpEventType } from '@angular/common/http';
import { slugify } from '@frontend/unhideschool/app/helpers/utility-functions';
import { ApplicationSelectors } from '@frontend/unhideschool/app/shared-nxgs/application/application.selectors';
import { FetchGalleries } from '@frontend/unhideschool/app/shared-nxgs/galleries/galleries.actions';
import { GalleriesSelectors } from '@frontend/unhideschool/app/shared-nxgs/galleries/galleries.selectors';
import { ViewAllDialogModel } from '@frontend/unhideschool/app/utils/i18n/locale/view-all-dialog/view-all-dialog.i18n.model';
import { UseTranslations } from '@frontend/unhideschool/app/utils/i18n/use-translation.decorator';
import { SeoTagsManagerService } from '@frontend/unhideschool/core/services/seo-tags-manager.service';
import { Gallery } from '@frontend/unhideschool/shared/models/dtos/gallery.model';
import { VideoPlayerCoursePlaylistVideo } from '@frontend/unhideschool/shared/models/dtos/video-player-course.model';
import { LoggedUserStateService } from '@frontend/unhideschool/app/core/services/legacy/logged-user.state';
import { PostTypeConfig } from '@frontend/unhideschool/app/shared/enums/post-type.enum';

enum SpecialCommunityCategories {
  'UNHIDE Contests' = 1,
  'Mentoria' = 2
}

declare const dataLayer;

interface Translations {
  "view-all-dialog": ViewAllDialogModel
}
@Component({ template: '<router-outlet></router-outlet>' })

@UseTranslations({
  translatableComponents: ["view-all-dialog"]
})

export class ViewallComponent implements OnInit, OnDestroy {

  translations: Promise<Translations>;

  _paramsObserver$ = new Subject();

  dialogRef: MatDialogRef<ViewAllDialogComponent>;
  overlayRef: OverlayRef;

  projectPosts: ProjectPost[];
  currentProjectIndex: number;
  otherProjectPosts: ProjectPost[];

  mePurchasedProducts$: Observable<number[]>;
  productOwn: boolean;
  gallery: Gallery;
  currentRoute: string;

  nextPosition: boolean;
  prevPosition: boolean;

  slideLoadEnabled = false;

  projectFiles = new BehaviorSubject<any>([]);

  postTypeConfig = this.getPostTypeByRouter();

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private ar: ActivatedRoute,
    private psto: ProjectStore,
    private utils: UtilityService,
    private title: Title,
    private meta: Meta,
    private store: Store,
    private overlay: Overlay,
    private projectService: ProjectService,
    private renderer: Renderer2,
    private http: HttpClient,
    private seotms: SeoTagsManagerService,
    private luss: LoggedUserStateService
  ) {
    this.overlayRef = this.overlay.create({
      width: '100%',
      height: '100%',
    });

    const spinnerPortal = new ComponentPortal(SpinnerOverlayComponent);

    this.overlayRef.attach(spinnerPortal);
  }

  ngOnInit() {

    this.currentRoute = this.router.url.replace(/\?.*/, '')
    setTimeout(() => {
      if (this.dialogRef == null) {
        this.dialogRef = this.dialog.open(ViewAllDialogComponent, {
          width: '100%',
          maxWidth: '100%',
          panelClass: 'view-all-dialog__panel'
        });
      }

      this.observeGalleryNavigation();
      this.observeDialogBeforeClosed();
      this.observeDialogAfterClosed();
      this.observeNavigateToRoute();
      this.handleNavigateToLesson();


      this.ar.params.pipe(
        switchMap(ps => {
          if (ps.postid != null && ps.postid > 0) {
            const projectPostCheck$ = this.projectService.getProjectPostId(ps.postid, this.postTypeConfig?.postTypeId);
            return combineLatest([projectPostCheck$, this.ar.params]);
          }

          this.router.navigate(['/home']);
          return undefined;
        }),
        takeUntil(this._paramsObserver$),
      ).subscribe(([projectPosts, { postid }]) => {
        this.projectPosts = projectPosts;
        this.currentProjectIndex = projectPosts.findIndex(pp => pp.postid == postid);

        this.validateCourseAccess(this.projectPosts[this.currentProjectIndex]);
        this.initializeArrows(projectPosts[this.currentProjectIndex]);
        this.populateViewAllDialog(postid, this.currentRoute);
        this.setGTMData(this.projectPosts[this.currentProjectIndex]);
        this.fetchLastWatchedLesson(postid)
        this.seotms.setStructuredData(projectPosts[this.currentProjectIndex])
        
      });
    }, 10);


  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'zendesk-in-viewall');
  }

  validateCourseAccess(post?: ProjectPost): void {
    const closeAndRedirect = () => {
      this.dialog.closeAll();
      this.ngOnDestroy();
      this.router.navigate(['/cursos']);
    }

    if (!post) {
      closeAndRedirect();
    }
    const isComingSoon = post?.traits?.some((trait) => trait.internalname === 'comingsoon');
    const isPublished = post?.published;
    const isLogged = this.luss.isLogged;
    if (!isLogged && (isComingSoon || !isPublished)) {
      return closeAndRedirect()
    }

    this.store.select(ApplicationSelectors.getUserCoursesIdList()).pipe(
      filter(list => list != null)
      ).subscribe(
      (coursesIdList) => {
        const isAdmin = this.luss.isAdmin;
        const isActive = post?.active;
        const isPublished = post?.published;
        const isComingSoon = post?.traits?.some((trait) => trait.internalname === 'comingsoon');
        const userHasCourse = coursesIdList?.length > 0 ? coursesIdList?.some(id => id === post?.postid) : false;
        const normalUserRules = [(!isActive && !userHasCourse), isComingSoon, (!isPublished && !userHasCourse)];
        const shouldRedirect = isAdmin ? false : this.applyValidationRules(normalUserRules);
        if (shouldRedirect) {
          closeAndRedirect();
        }
      })

  }

  applyValidationRules(rules: Boolean[]) {
    return rules.some(rules => rules);
  }

  updateMetaTags(project: ProjectPost) {
    const title = project.title + '| UNHIDE School';
    const creator = project.creator.alias;
    const thumbnail = project.postthumbnail + '?tr=w-250';

    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:description', content: 'Instrutor: ' + creator });
    this.meta.updateTag({ property: 'og:image', content: thumbnail });
    this.meta.updateTag({ property: 'og:url', content: window.location.href });
    this.seotms.initializeCustomMetaTagsFromList(project.title)

  }

  populateViewAllDialog(postid: number, currentRoute: string) {
    this.store.dispatch(new FetchMentoringData(postid)).subscribe(() => this.setMentoringTopicUrl());

    // TODO: Refatorar essa linha
    // tip: salvar referencia para o o elemento mat-dialog-container
    const dialogContent = document.querySelector('.mat-mdc-dialog-surface');
    dialogContent.scrollTo(0, 0);

    const projectPost = this.projectPosts[this.currentProjectIndex];
    projectPost.galleries[0] ?
      this.store.dispatch(new FetchGalleries({
        galleryType: 'ProjectGalleries',
        filters: [{
          name: 'project',
          value: projectPost.galleries[0].galleryid
        }],
        pagination: {
          page: 1,
          perpage: 20
        }
      }
      ))
        .pipe(
          switchMap(() => this.store.select(GalleriesSelectors.getProjectGalleries(projectPost.galleries[0].galleryid))),
          catchError((err) => {
            throw new Error('err');
          })
        )
        .subscribe(gallery => {
          this.gallery = gallery;

          if (this.dialogRef.componentInstance)
            this.dialogRef.componentInstance.parentGallery = gallery;
        }) : null;
    this.updateMetaTags(projectPost);

    this.store.dispatch(new FetchOthersPosts(1, 10, this.postTypeConfig?.postTypeId)).subscribe(() => {
      this.store.dispatch(new UpdateOthersPostsPagination(1, 10));
      this.slideLoadEnabled = true;
      this.dialogRef.componentInstance.projects = this.store.selectSnapshot(ViewAllSelectors.getOthersPosts());
    });

    this.dialogRef.componentInstance.onSlideChange.subscribe(change => {
      const isLimit = this.store.selectSnapshot(ViewAllSelectors.getOthersPostsLimit());
      const page = this.store.selectSnapshot(ViewAllSelectors.getOthersPostsPagination()).page;

      if (!isLimit && this.slideLoadEnabled) {
        this.store.dispatch(new LoadmoreOthersPosts(page + 1, 10, this.postTypeConfig.postTypeId)).subscribe(() => {
          this.dialogRef.componentInstance.projects = this.store.selectSnapshot(ViewAllSelectors.getOthersPosts());
          this.slideLoadEnabled = true;
          this.store.dispatch(new UpdateOthersPostsPagination(page + 1, 10));

        })
      }
    });

    this.dialogRef.componentInstance.project = projectPost;
    this.dialogRef.componentInstance.routerData = {
      currentRoute,
    };

    const hasActivePromotion = this.store.selectSnapshot(ApplicationSelectors.hasActivePromotion());
    const activePromotion = this.store.selectSnapshot(ApplicationSelectors.getActivePromotion());
    this.dialogRef.componentInstance.hasActivePromo = hasActivePromotion;
    this.dialogRef.componentInstance.activePromo = activePromotion;

    // this.dialogRef.componentInstance.parentGalleryTeste(projectPost)
    const standaloneArrows = this.nextPosition || this.prevPosition;

    this.dialogRef.componentInstance.standaloneArrows = standaloneArrows;

    if (standaloneArrows) {
      this.dialogRef.componentInstance.maxIndex = 1

    } else {
      this.dialogRef.componentInstance.maxIndex = this.projectPosts.length - 1;

    }

    this.dialogRef.componentInstance.currIndex = this.currentProjectIndex;


    this.store.dispatch(new FetchViewallLessons(postid)).subscribe(() => {
      this.populateGroupedLessons();
    });

    this.populateMediaFiles(postid);
    this.handleDownloadAssetChange();
    this.translations.then((translations) => {
      this.dialogRef.componentInstance.viewAllDialogMock = translations['view-all-dialog'];
    })
  }

  private populateGroupedLessons() {
    this.store.select(ViewAllSelectors.getPostLessons()).subscribe((lessonsUnordered: VideoPlayerCoursePlaylistVideo[]) => {
      const lessons: LessonItem[] = lessonsUnordered.filter(lesson => lesson.active).map(lesson => new LessonItem(lesson));
      const totalViews = lessons.reduce((acc, cv: LessonItem) => cv.views != undefined ? acc + cv.views : acc, 0);
      const groupedLessons = groupCollectionByKey<LessonItem>(lessons, 'groupname');

      if(this.dialogRef.componentInstance){
        this.dialogRef.componentInstance.groupedLessons = groupedLessons;
        this.dialogRef.componentInstance.totalViews = !isNaN(totalViews) ? totalViews : 0;
      }

    });
  }

  private setMentoringTopicUrl() {
    const mentoringTopicData = this.store.selectSnapshot(ViewAllSelectors.getMentoringData());
    if (mentoringTopicData != null) {
      const communityid = mentoringTopicData.communityid;
      const communitypath = slugify(mentoringTopicData.path);
      const communitycategid = mentoringTopicData.communitycategid;
      const communitycategname = slugify(SpecialCommunityCategories[communitycategid]);
      const mentoringTopicUrl = `/forum/topicos/${communitycategid}/${communitycategname}/${communityid}/${communitypath}`;
      this.dialogRef.componentInstance.mentoringTopicUrl = mentoringTopicUrl;
    }
  }

  private populateMediaFiles(postid: number) {
    const currentState = this.psto.readState<PostRelatedUrlFilesState>('postRelatedUrlFiles');
    const postMediaFiles$ = !currentState[postid] ? this.psto.getPostRelatedUrlFiles(postid) : of(currentState[postid]);

    postMediaFiles$.subscribe(urlFiles => {
      if (urlFiles) {
        const mediafiles = urlFiles.map(uf => ({
          name: uf.name,
          url: uf.content
        }));
        this.projectFiles.next(this.dialogRef.componentInstance.mediafiles = mediafiles);
      }
      this.projectFiles.subscribe(files => this.dialogRef.componentInstance.mediafiles = files);

    });
  }


  handleDownloadAssetChange() {
    this.dialogRef.componentInstance.onDownloadAsset.subscribe(file => {

      const updateFile = () => {
        const newstate = this.projectFiles.getValue();
        // let obj = newstate.find(statefile => statefile.name == file.name);
        // obj = file;
        this.projectFiles.next(newstate);
      }

      file['inprogress'] = true;
      file['completed'] = false;
      file['progress'] = 0;
      file['canceldownloadtoggle'] = new Subject();
      file['cancelled'] = false;

      const fetchFromServer = this.http.get(file.url, {
        observe: 'events',
        reportProgress: true,
        responseType: 'blob'
      });

      file['request'] = fetchFromServer;
      updateFile();
      file['request'].pipe(
        takeUntil(file['canceldownloadtoggle']),
        tap((event: any) => {
          if (event.type == HttpEventType.DownloadProgress) {
            const progress = Math.round(100 * event.loaded / event.total) > 0 ? Math.round(100 * event.loaded / event.total) : 0;
            file['progress'] = progress > file['progress'] ? progress : file['progress'];
          }
          updateFile();

        }),
        filter((res: any) => res.type == 4),
        map((res: any) => res.body),
      ).subscribe((blob) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.setAttribute("download", file.name);
        a.click();
        file['inprogress'] = false;
        file['completed'] = true;
        file['cancelled'] = false;
        file['progress'] = 0;
        updateFile();

      });
    });
    this.dialogRef.componentInstance.onCancelDownload.subscribe(file => {
      const updateFile = () => {
        const newstate = this.projectFiles.getValue();
        // let obj = newstate.find(statefile => statefile.name == file.name);
        // obj = file;
        this.projectFiles.next(newstate);
      }
      file['canceldownloadtoggle'].next('');
      file['cancelled'] = true;
      updateFile();
    })
  }

  private setGTMData(post) {
    const gtmData = {
      product_id: post.postid,
      content_type: 'product',
      product_name: post.title,
      product_price: post.product.price,
      product_creator: post.creator.alias,
    };

    dataLayer.push({ event: 'view_content', ...gtmData });
  }

  observeGalleryNavigation() {
    const obs = this.dialogRef.componentInstance.navigate.pipe(
      takeUntil(this.dialogRef.beforeClosed())
    );
    obs.subscribe((data: any) => {
      if (!data.traits.some((trait) => trait.internalname == 'comingsoon')) {
        let router = 'view-all';

        if (this.currentRoute.includes('/home/')) {
          router = 'cursos/view-all';
        }

        this.router.navigate(
          [router, data.postid, this.utils.slugify(data.title)],
          {
            relativeTo: this.ar.parent,
            queryParamsHandling: 'preserve',
          }
        );
      }
    });
  }

  observeDialogBeforeClosed() {
    this.dialogRef.beforeClosed().subscribe(() => {
      this.overlayRef.detach();
    });
  }

  observeDialogAfterClosed() {
    this.dialogRef.afterClosed().subscribe((redirect = true) => {
      this.clearDialogAfterClosed();

      if (redirect) {
        this.router.navigate(['.'], { relativeTo: this.ar.parent, queryParamsHandling: 'preserve' });
      }
    });
  }

  clearDialogAfterClosed() {
    this._paramsObserver$.next({});
    this._paramsObserver$.complete();
    // Reset page Title
    this.title.setTitle('UNHIDE SCHOOL');
  }

  observeNavigateToRoute() {
    const obs = this.dialogRef.componentInstance.navigateToRoute.pipe(
      takeUntil(this.dialogRef.beforeClosed())
    );

    obs.subscribe((payload: any) => {
      this.dialogRef.close(false);
      // this.router.navigateByUrl(payload.url);
      window.location.href = payload.url;
    });
  }

  initializeArrows(projectpost: ProjectPost) {
    this.projectService.getNextPrevPostsByPosition(projectpost?.position, this.postTypeConfig?.postTypeId).subscribe(nextNprev => {
      const next = nextNprev.nextPost.length > 0;
      const prev = nextNprev.prevPost.length > 0;
      this.dialogRef.componentInstance.standaloneArrows = next || prev;
      this.dialogRef.componentInstance.maxIndex = 2;
      this.dialogRef.componentInstance.nextPost = nextNprev.nextPost;
      this.dialogRef.componentInstance.prevPost = nextNprev.prevPost;
    });
  }

  handleNavigateToLesson() {
    this.dialogRef.componentInstance.navigatedToLesson.subscribe(() => {
      const lastWatchedLesson = this.store.selectSnapshot(ViewAllSelectors.getLastWatchedLesson())
      if(!lastWatchedLesson) {
        return this.router.navigateByUrl(this.getFirstLessonUrl());
      } else {
        return this.router.navigateByUrl(this.getLastWatchedLessonUrl(lastWatchedLesson.orderedpostpartid, lastWatchedLesson.title))
      }

    })
  }

  getLastWatchedLessonUrl(orderedpostpartid: number, title: string) {
    const { project} = this.dialogRef.componentInstance;
    const projectTitleSlug = this.utils.slugify(project.title);
    
    return `/post/${project.postid}/${orderedpostpartid}/${projectTitleSlug}/${this.utils.slugify(title)}`;
  
  }

  getFirstLessonUrl() {
    const {groupedLessons, project} = this.dialogRef.componentInstance;
    const firstLesson = groupedLessons[0];
    const firstLessonItem = firstLesson.items[0];
    const firstLessonTitleSlug = this.utils.slugify(firstLessonItem.title);
    const projectTitleSlug = this.utils.slugify(project.title);
    if (groupedLessons.length > 0) {
      firstLesson;
    }
    return `/post/${project.postid}/${firstLessonItem.oppid}/${projectTitleSlug}/${firstLessonTitleSlug}`;
  }

  fetchLastWatchedLesson(postId: number) {
    if (this.isCourse())
      this.store.dispatch(new FetchLastWatchedLesson(postId));
  }

  getPostTypeByRouter() {
    return Object.values(PostTypeConfig).find((postType) =>
      this.router.routerState.snapshot.url.includes(postType.viewAllPath)
    );
  }

  isCourse() {
    return this.postTypeConfig === PostTypeConfig.COURSE;
  }
}
