import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { LoggedUserStateService } from '../services/legacy/logged-user.state';

@Injectable({
  providedIn: 'root',
})
export class SpaceGuard implements CanActivate {
  constructor(private luss: LoggedUserStateService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    console.log(this.luss.isPlanoTeamOwner, 'team')

    if (!this.luss.isPlanoTeamOwner) {
      const defaultRedirect = '/home';
      const redirectTo =
        route.data != null && route.data.redirectTo != null
          ? route.data.redirectTo
          : defaultRedirect;
      this.router.navigate([redirectTo]);
      return false;
    }

    return true;
  }
}
