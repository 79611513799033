export const PostTypeConfig = {
  COURSE: {
    postTypeId: 1,
    workSpaceId: 2,
    name: 'curso',
    viewPath: '/post',
    viewAllPath: '/curso',
  },
  TUTORIAL: {
    postTypeId: 7,
    workSpaceId: 2,
    name: 'tutorial',
    viewPath: '/tutorial/view',
    viewAllPath: 'null',
  },
  PACK: {
    postTypeId: 8,
    workSpaceId: 2,
    name: 'pack',
    viewPath: '/pack/view',
    viewAllPath: '/pack/view-all',
  },
  WORKSPACE: {
    postTypeId: 1,
    workSpaceId: 1,
    name: 'myspace',
    viewPath: '/pack/view',
    viewAllPath: '/pack/view-all',
  },
};

export interface IPostTypeConfig {
  postTypeId: number;
  name: string;
  viewPath: string;
  viewAllPath: string;
  workSpaceId?: number;
}
