import { MeAccessModel } from '@frontend/unhideschool/app/modules/membership/membership-subscription.selectors';
import { IWorkspace } from '../logged-user.model';

export interface MeInfoQueryData {
  user: {
    uid: number;
    alias: string;
    profilepicture: string;
    relatedmedia: any;
    role: {
      internalname: string;
    };
    workspaces: IWorkspace[];
  };

  courses: { postid: number }[];

  subscribed: boolean;
  accesses: MeAccessModel;
}

export const meInfoQuery = `
    query MeBasicInfoQuery {
        me {
            user {
                uid
                alias
                profilepicture
                relatedmedia{
                  imagesettings {
                    imagesettingid
                    width
                    height
                    waspectratio
                    haspectratio
                    xcenter
                    ycenter
                  }
                  mediacontenttype{
                    internalname
                  }
                }
                role {
                    internalname
                }
             workspaces {
                id
                workspaceId
                    workspace{
                        slug
                    }
                roleId
                 userId
            }
            }
            courses {
                postid
              },
            subscribed
            accesses{
            subscription{
              subscriptionplan {
                rootplan{
                  internalname
                  name
                }
              }
            }
          }
        }
    }
`;
