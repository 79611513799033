import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { take, filter, switchMap, map, tap } from 'rxjs/operators';

import { mockUserMenuItens } from '@frontend/unhideschool/app/core/models/mocks/user-menu-data.mock';
import { NotificationItem } from '@frontend/unhideschool/app/core/models/notification-item.model';
import { SubscriptionMemberStates } from '@frontend/unhideschool/shared/models/subscription/subscription-members.model';
import { SubscriptionMemberState } from '@frontend/unhideschool/shared/models/subscription/subscription-form-data.model';

import { MembershipSubscriptionStore } from '@frontend/unhideschool/modules/membership/stores/membership-subscription.store';
import { Select, Store } from '@ngxs/store';
import { UserSelectors } from '@frontend/unhideschool/app/shared-nxgs/user/user.selectors';
import { UserStateModel } from '@frontend/unhideschool/app/shared-nxgs/user/user.model';
import { FetchUser } from '@frontend/unhideschool/app/shared-nxgs/user/user.actions';
import { ApplicationSelectors } from '@frontend/unhideschool/app/shared-nxgs/application/application.selectors';
import { NavigationEnd, Router } from '@angular/router';
import { userSideBar } from './mocks/sidebar';
import { Menus, UnhSidebarModel } from './interfaces/unh-sidebar.interface';

import {
  menuSectionFour,
  menuSectionOne,
  menuSectionTree,
  menuSectionTwo,
} from './mocks/sidebar-submenu';

import { UpdateSidebarToggle } from '@frontend/unhideschool/app/shared-nxgs/application/application.actions';
import { UseTranslations } from '@frontend/unhideschool/app/utils/i18n/use-translation.decorator';
import { interpolate, slugify } from '@frontend/unhideschool/app/helpers/utility-functions';
import { environment } from '@frontend/unhideschool/env';

import { formatISO, formatDistanceToNow } from 'date-fns';

import { AuthService } from '../../services/auth.service';
import { UnreadUserNotification, UserMenuItem, isUnreadNotification } from '../../models';
import { LoggedUserStateService } from '../../services/legacy/logged-user.state';
import { SideMenuStateService } from '../../services/legacy/side-menu.state';
import { UserNotificationsStore } from '../../services/legacy/user-notifications.store';
import { SubscriptionFormDataStore } from '../../services/legacy/subscription-member-form-data.store';
import { getCroppedImageUrl } from '@frontend/unhideschool/app/helpers/relatedmedia-and-crop.helper';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '@frontend/unhideschool/app/modules/signin/pages/change-password/change-password.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangeEmailComponent } from '@frontend/unhideschool/app/modules/signin/pages/change-email/change-email.component';
import { UserSideBar } from '@frontend/web-components/types/components/interfaces/compositions/sidebar/sidebar.model';

interface Translations {
  'unh-sidebar': UnhSidebarModel
}

@Component({
  selector: 'unh-menu-content-footer',
  templateUrl: './menu-content-footer.layout.html',
  styleUrls: ['./menu-content-footer.layout.scss'],
})
@UseTranslations({
  translatableComponents: ["unh-sidebar"]
})
export class MenuContentFooterComponent implements OnInit {
  args: any = {
    currentPath: '',
    userMenu: {
      open: false,
      user: userSideBar
    },
    open: true,
    menus: [menuSectionOne],
    currentMenu: 'Meu Perfil',
    appendSidebarOpen: false
  }

  loginMenuItem: Menus = {
    icon: {
      url: environment.cdnUrl + '/last-contests-sidebar-icon.svg',
    },
    content: {
      title: {
        open: 'Cadastre-se',
        collapsed: 'Cadastrar'
      },
      path: '/signup'

    },
  }

  registerMenuItem: Menus = {
    icon: {
      url: environment.cdnUrl + '/last-contests-sidebar-icon.svg',
    },
    content: {
      title: {
        open: 'Acesse sua conta',
        collapsed: 'Acessar'
      },
      path: '/login'

    },
  }

  currentMenu = '';
  compactMenu = true;
  islogged = false;
  isadmin: boolean;
  notificationsOpen = false;
  notificationsMenuName: string;
  notificationsMenuTitle: string;
  notifcationsMenuItens$: Observable<NotificationItem[]>;
  unreadNotifs$: Observable<UnreadUserNotification[]>;
  userMenuName: string;
  userMenuTitle: string;
  hasCourse = false;
  userMenuItens: UserMenuItem[] = [];
  types = ['subscriptionwarning', 'subscriptionbackwarning', 'subscriptionexpiredwarning'];
  sidebarTranslation;

  currLanguage: any;
  item: any;

  translations: Promise<Translations>;

  get notificationsMenuIsOpen$(): Observable<boolean> {

    return this.smss.getMenuOptionState(this.notificationsMenuName, 'isopen');
  }

  get userMenuIsOpen$(): Observable<boolean> {
    return this.smss.getMenuOptionState(this.userMenuName, 'isopen');
  }

  get isSmallScreen() {
    return this.breakpointObserver.isMatched('(max-width: 1200px)');
  }

  userMenu$ = new BehaviorSubject(userSideBar);
  userMenu: UserSideBar = null;
  user$: Observable<UserStateModel>;

  @Select(ApplicationSelectors.hasSubscriptionWarning())
  hasWarning$: any;

  @Select(ApplicationSelectors.getActivePromotion())
  hasActivePromotion$: Observable<boolean>;

  constructor(
    public luss: LoggedUserStateService,
    private breakpointObserver: BreakpointObserver,
    private smss: SideMenuStateService,
    private uns: UserNotificationsStore,
    private mps: MembershipSubscriptionStore,
    private smfds: SubscriptionFormDataStore,
    private store: Store,
    private router: Router,
    private auths: AuthService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {
    this.translations.then((translations) => {
      this.luss.isLogged$.subscribe(logged => {
        this.islogged = logged;
      });

      this.resolveTranslations(translations);
    });
  }

  private initUserData() {
    this.user$ = this.store
      .select(UserSelectors.getUser())
      .pipe(tap(user => {
        (this.hasCourse = user.courses?.length > 0)
        // this.userMenu$.next(this.updateUserMenu(this.luss.userProfileInfo.alias))
      }));

    this.store.dispatch(new FetchUser());
  }

  updateUserMenu(name): UserSideBar {
    const template = this.args.userMenu.user;
    return {
      ...template,
      name,

    }
  }
  ngOnInit() {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      tap((e: NavigationEnd) => {
        this.observeInitialMenu(this.sidebarTranslation);
      })
    ).subscribe();
    if (this.luss.isLogged) {
      this.initUserData();
      this.unreadNotifs$ = this.uns.selectState<UnreadUserNotification[]>('unreadNotifications').pipe(
        map(n => n.filter(notif => !this.types.includes(notif.notifcateg.internalname)))
      );
      this.initSidebarUserData();
      this.initUserMenu();
      this.initUserNotifications();
    }
  }

  initUserMenu() {
    this.userMenuName = 'user_menu';
    this.userMenu$.subscribe(menu => {
      this.userMenu = { ...menu };
    })
    this.user$.subscribe(user => {
      if (user.courses?.length !== 0) {
        this.hasCourse = true;
      }
    });

    this.userMenuIsOpen$
      .pipe(filter(isopen => isopen === true), take(1))
      .subscribe(() => {
        const user = this.luss.user;
        this.userMenuTitle = user.alias;
        mockUserMenuItens[1].link = `/perfil/${this.luss.uid}/${slugify(this.luss.user.alias)}`;
        this.userMenuItens = mockUserMenuItens
          .filter(umi => {
            const notMyCourses = umi.link.indexOf('meus-cursos') === -1;
            return notMyCourses || this.hasCourse;
          })
          .map(ami => {
            return { ...ami, link: interpolate(ami.link, user) };
          });
      });
  }

  initUserNotifications() {
    this.notificationsMenuName = 'notifications_menu';
    this.notificationsMenuTitle = 'Notificações';
    this.uns.fetchStoreStateData('notifications').subscribe((notifications: any) => {
      const unreadCount = notifications.filter(notif => (!notif.read)).length;
      const userMenu = this.args.userMenu;
      const notificationMenuItem = userMenu.user.menus.find(menu => menu.content?.action?.name == "show-notifications");
      notificationMenuItem.count = unreadCount;
      const nargs = this.args;
      this.item = notificationMenuItem;
      nargs.userMenu = userMenu;
      this.args = nargs;
      this.notifcationsMenuItens$ = of(notifications);
      return notifications.filter(notif => {
        const blacklist = ['subscriptionwarning', 'subscriptionbackwarning', 'subscriptionexpiredwarning'];
        return !(blacklist).includes(notif.notifcateginternalname);
      });
    });
  }

  markNotificationsAsRead(notifications: (NotificationItem | UnreadUserNotification)[]) {
    const types = ['subscriptionwarning', 'subscriptionbackwarning', 'subscriptionexpiredwarning'];

    const notificationids = notifications.reduce((acc, n) => {
      let internalname: string;

      if (isUnreadNotification(n)) {
        internalname = n.notifcateg.internalname;
      } else {
        internalname = n.notifcateginternalname;
      }

      if (!types.includes(internalname)) {
        return [...acc, n.notificationid];
      }

      return acc;
    }, []);

    this.uns.markNotificationsAsRead(notificationids);
  }

  handleNotificationActions(payload: { action: string, item: NotificationItem }) {
    switch (payload.action) {
      case SubscriptionMemberStates.ACCEPTED:
        this.handleInvitation(payload.action, payload.item);
        break;

      case SubscriptionMemberStates.DECLINED:
        this.handleInvitation(payload.action, payload.item);
        break;
    }

    if (!payload.item.read) {
      this.markNotificationsAsRead([payload.item]);
    }

    this.closeMenu(!payload.item.disableNavigation);
  }

  handleUserMenuItemClick(name: string | Event) {
    if (name instanceof Event) {
      return;
    }

    const openDialog = cp => {
      this.closeMenu(true);
      return (
        this.dialog
          .open(cp, {
            backdropClass: 'unh-dark-overlay',
            hasBackdrop: true,
            width: '100%',
            maxWidth: '420px'
          })
          .afterClosed()
          .subscribe(data => this.handleEmailAndPassDialogClose(data))
      );
    };

    switch (name) {
      case 'change-pass':
        openDialog(ChangePasswordComponent);
        break;

      case 'change-email':
        openDialog(ChangeEmailComponent);
        break;

      case 'show-notifications':
        this.notificationsOpen = !this.notificationsOpen;
        break;

      case 'logout':
        this.logout();
        break;
    }
  }

  handleEmailAndPassDialogClose(data?: { message: string, success: boolean }) {
    if (!data) return;
    this.snackbar.open(data.message, 'OK', {
      duration: 10000,
      verticalPosition: 'top'
    });
  }

  closeMenu(condition = true) {
    if (condition) {
      this.smss.closeAll();
    }
    this.notificationsOpen = false;
  }

  private handleInvitation(memberStateInternalname: SubscriptionMemberStates, notificationItem: NotificationItem) {
    const subscritionmemberid = notificationItem.data.subscriptionmemberid;
    const accepted = SubscriptionMemberStates.ACCEPTED === memberStateInternalname;

    const dateNow = new Date();
    const dateCreated = formatISO(dateNow);

    // Build updated Notification Item
    const updatedNotifcation = notificationItem.clone({
      data: { ...notificationItem.data, accepted },
      datecreated: formatDistanceToNow(dateNow)
    });

    const backupStoreState = this.uns.updateNotificationItem(updatedNotifcation);

    this.smfds
      .findInCollection<SubscriptionMemberState>('memberstates', 'internalname', memberStateInternalname)
      .pipe(
        switchMap(ms => this.mps.updateSubscriptionMemberState(subscritionmemberid, ms.subscriptionmemberstateid)),
        switchMap(() => this.uns.updateUserNotification(notificationItem.notificationid, updatedNotifcation.data, dateCreated))
      )
      .subscribe({ error: () => this.uns.restoreState('notifications', backupStoreState) });
  }

  private logout() {
    this.closeMenu();
    this.auths.logout();
  }

  menuChanged(evt: Event) {
    const menuItem = (evt as CustomEvent).detail;

    if (menuItem.action != undefined) {
      this.handleUserMenuItemClick(menuItem.action.name);
    } else {
      this.router.navigate([menuItem.path])
    }

    if (this.compactMenu == false) {
      this.compactMenu = true;
    }
  }

  languageChanged(evt: Event) {
    const { languageCode } = (evt as CustomEvent).detail;
    setTimeout(() => document.location.reload(), 300);
    this.auths.setAcceptedLanguage(languageCode);
  }

  toggleHandler(evt: Event) {
    const toggle = (evt as CustomEvent).detail;
    this.forceSwiperDomUpdate();
    if (toggle != this.compactMenu) {
      this.compactMenu = toggle;
    }

    this.store.dispatch(new UpdateSidebarToggle(toggle));
  }

  forceSwiperDomUpdate() {
    const resizeInterval = setInterval(() => {
      window.dispatchEvent(new Event('resize'));  // Temporally fix
    }, 1)
    setTimeout(() => {
      clearInterval(resizeInterval);
    }, 300)
  }

  changeNotificationsState(evt: Event) {
    this.notificationsOpen = (evt as CustomEvent).detail;
  }

  initSidebarUserData() {
    this.luss.userProfileInfo$.subscribe(userProfileInfo => {
      const user = userProfileInfo;

      if (user && userProfileInfo) {
        this.args.userMenu.user.name = user.alias;
        const userProfileInfo = this.luss.userProfileInfo;
        this.applyUserCareersPath();
        try {
          const mediaprofile = userProfileInfo.relatedmedia.find(
            rm => rm.mediacontenttype && rm.imagesettings ? rm.mediacontenttype.internalname == 'profilepicture' : null);

          if (mediaprofile) {
            const imagesettings = mediaprofile.imagesettings;
            this.args.userMenu.user.avatar = getCroppedImageUrl(user.profilepicture, imagesettings, 37, 860);
          } else {
            this.args.userMenu.user.avatar = user.profilepicture;
          }
        } catch (e) {
          this.args.userMenu.user.avatar = user.profilepicture;
        }

        this.args.userMenu.user.work
        this.args.userMenu.user.work = userProfileInfo.occupationtitle;
        this.args.userMenu.user.isPro = this.luss.subscribed;
        this.args.userMenu.user.menus[1].content.path = `/perfil/${this.luss.uid}/${slugify(this.luss.user.alias)}`;
        this.unreadNotifs$.subscribe(unread => {
          const notificationMenuItem = this.args.userMenu.user.menus
            .find(menu => menu.content?.action?.name == "show-notifications");
          notificationMenuItem.count = unread.length;

          if (unread.length > 0) {
            this.args.userMenu.user.isBadge = true;
          } else {
            this.args.userMenu.user.isBadge = false;
          }
          this.userMenu$.next(this.args.userMenu.user);

        });
      }
    })
  }

  findMenu(r) {
    let path;
    let menu;
    try {
      path = window.location.pathname.includes('perfil') ? this.args.userMenu.user.menus[1].content.path : window.location.pathname;
      if (this.luss.subscribed && this.luss.isAdmin) {
        menu = this.args.menus[2]?.modes[0].operations.find(m => path == m.content.path);
        if (menu !== undefined) {
          return menu;
        }
      }
      menu = this.args.menus[1]?.menus.find(m => path == m.content.path);
    } catch (e) {
      console.log(e)
    }
    return menu;
  }

  observeInitialMenu(sidebarTranslation) {
    try {

      // Init current selected language;
      const storedLanguage = this.auths.acceptLanguage;
      this.currLanguage = sidebarTranslation.user_sidebar_menu.languages.find(lang => lang.languageCode == storedLanguage);
      userSideBar.currentLang = this.currLanguage;

      // init current menu;
      const path = window.location.pathname.includes('perfil') ? this.args?.userMenu?.user?.menus[1].content?.path : window.location.pathname;
      const menu = this.findMenu(sidebarTranslation);
      const submenus = this.args?.menus[0].submenus;
      const usermenu = this.args?.userMenu?.user?.menus?.find(m => path == m.content.path);
      if (menu != undefined) {
        this.currentMenu = menu.content.title.open;
      } else if (usermenu != undefined) {
        this.currentMenu = usermenu.content.title.open;
      } else {
        let submenufound;

        if (submenus?.length) {
          for (const submenu of submenus) {
            submenufound = submenu.menus.find((m) => path == m.content.path);

            if (submenufound != undefined) {
              this.currentMenu = submenufound.content?.title?.open;
              break;
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }


  resolveTranslations(translations: Translations) {
    const sidebar = translations['unh-sidebar'];
    this.sidebarTranslation = sidebar;
    this.args.userMenu.user = sidebar.user_sidebar_menu;
    this.args.menus = [sidebar.regular_sidebar_menu]
    this.loginMenuItem = sidebar.regular_sidebar_menu.loginMenuItem;
    this.registerMenuItem = sidebar.regular_sidebar_menu.registerMenuItem;
    this.addManageMenuSection();

  }

  addManageMenuSection() {
    if (this.luss.isAdmin) {
      this.args.menus = [this.sidebarTranslation.regular_sidebar_menu, menuSectionTwo]
    } else {
      this.args.menus = [this.sidebarTranslation.regular_sidebar_menu]
    }


    if (
      this.luss.isPlanoTeam
    ) {
      this.args.menus.unshift(menuSectionTree);
      this.args.menus.push(menuSectionFour)
    }
    this.observeInitialMenu(this.sidebarTranslation);
  }

  applyUserCareersPath() {
    if(this.luss.subscribed){
      this.args.userMenu.user.menus[0].content.path = '/carreiras'
    }
  }
}
