export const courseAdminCreateProjectMutation = `
mutation createCourse(
  $creatoruid: Int!,
  $posttypeid: Int!,
  $title: String!,
  $active: Boolean!,
  $tags: [String],
  $softwareTagIds: [Int],
  $careerTagIds: [Int],
  $price: Float,
  $traits: [String],
  $postthumbnailid: Int,
  $postviewallheadid: Int,
  $postmobilecoverid: Int,
  $postthumbnailcoverid: Int,
  $fulldescription: InputMVStringBag,
  $productactive: Boolean!,
  $belongsToTheProCatalog: Boolean!
  $workspaceId: Int!
) {
CreateCourse(
courseData: {
  productactive:  $productactive,
  creatoruid: $creatoruid,
  posttypeid: $posttypeid
  title: $title,
  active: $active,
  tags: $tags,
  softwareTagIds: $softwareTagIds,
  careerTagIds: $careerTagIds,
  price: $price,
  traits: $traits,
  postthumbnailid: $postthumbnailid,
  postviewallid: $postviewallheadid,
  postmobilecoverid: $postmobilecoverid,
  postthumbnailcoverid: $postthumbnailcoverid,
  fulldescription: $fulldescription,
  belongsToTheProCatalog: $belongsToTheProCatalog,
  workspaceId: $workspaceId
}
  ) {
    course {
      postid
      title
      description
      contenttime
      published
      datepublished
      active
      position
      postthumbnail
      postthumbnailcover
      postviewallhead
      postmobilecover
      community{
        communityid
      }
      product{
        internalname
      }
      traits{
        internalname
      }
      entitlements{
        internalname
      }
      creator{
        alias
        uid
      }
      workspaceId
    }
  }
}

`;
